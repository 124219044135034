#_404_page {
  overflow: hidden;

  height: 100%;
  background: black;
  background-image: url("./assets/404.jpg");
  background-size: cover;
  background-position-x: center;
  background-position-y: center;

  cursor: default;
}

.container {
  overflow: hidden;
  height: 100%;
  backdrop-filter: blur(5px);
  background-color: rgba(#000, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: flex-end;
    padding-bottom: 20vh;
  }
}

#text_404 {
  position: relative;
  color: rgba(#fff, 0.75);
  font-size: 28vw;
  line-height: 29vw;
  font-weight: 200;
  text-shadow: 0px 0px 1vw #fff, 0px 0px 2.5vw #0055ff;

  //   animation-name: glow;
  //   animation-duration: 2s;
  //   animation-iteration-count: infinite;
  //   animation-direction: alternate;
  //   animation-timing-function: ease-in-out;
}

#text_404_0,
#text_404_0::after {
  content: "0";
  display: inline-block;
}

#text_404_0::after {
  animation: none;
  z-index: -1;
  font-size: 5vw;
  content: "NOT FOUND";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 45%;
  left: 0;
  text-align: center;
  filter: blur(2px);
  transform: scale(2.5);
}

#sub_404 {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 20px;
}

#_404_page .btn {
  padding: 15px 45px;
}

@keyframes glow {
  from {
    text-shadow: 0px 0px 1vw #fff, 0px 0px 2.5vw #0055ff;
  }
  to {
    text-shadow: 0px 0px 1vw #fff, 0px 0px 2vw #6647ff;
  }
}
